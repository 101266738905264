// override bootstrap variables
//
$spacing-unit:     30px;
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}
$font-family-sans-serif: Arial, 'Helvetica Neue', Helvetica, sans-serif;
$font-family-serif: Georgia, Times, 'Times New Roman', serif;
$font-family-monospace: Menlo, Monaco, Consolas, "Courier New", monospace;
$font-size-base: 16px;
$headings-font-family: $font-family-serif;

// import bootstrap
@import "bootstrap";
@import "bootstrap/_theme";
@import "syntax-highlighting";

.navbar { margin-bottom: 0; }
.navbar-brand img { margin-top: -15px; }

.carousel-control .control-prev,
.carousel-control .control-next {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
}
@media screen and (min-width: 768px) {
  .carousel-control .control-prev .fa,
  .carousel-control .control-next .fa { font-size: 4em; }
}
.carousel-control .control-prev { left: 50%; }
.carousel-control .control-next { right: 50%; }

footer {
  border-top: 1px solid #222;
  background-color: $gray-lighter;
  color: #999;
  margin-top: 20px;
}
.footer a {
  color: #777;
}
.footer a:hover {
  color: #222;
}
.footer-subtle {
  font-size: 70%;
  padding-top: 15px;
  padding-bottom: 5px;
}

